import React from "react";

function ProjectDetails({ project_updates = "", className = "" }) {
  return (
    <div className={`residence-nav border-bottom border-top ${className}`}>
      <div className="d-flex justify-content-center">
        <ul className="tab-list">
          <TabLi title="Project Synopsis" href="#synopsis" />
          <TabLi title="Location" href="#location" />
          <TabLi
            title="Project Specifications"
            href="#project-specifications"
          />
          <TabLi title="Gallery" href="#gallery" />
          {project_updates && (
            <TabLi title="Project Status" href="#project-status" />
          )}
        </ul>
      </div>
    </div>
  );
}

const TabLi = ({ href = "", title }) => {
  return (
    <li>
      <a className="button-as-link" href={href}>
        {title}
      </a>
    </li>
  );
};

export default ProjectDetails;
